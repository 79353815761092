import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, Optional, Output, Provider, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CalendarComponent } from '@proget-shared/form/calendar';
import { noop, Subscription } from 'rxjs';

import { DateParser } from '../../class/date-parser.class';
import { FilterActivatorDirective } from '../../directive/filter-activator.directive';
import { FilterDateConfig } from '../../type/filter-config.type';

const FILTER_DATE_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FilterDateComponent),
  multi: true,
};

@Component({
  selector: 'app-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss'],
  providers: [FILTER_DATE_VALUE_ACCESSOR],
})
export class FilterDateComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input()
  public config: FilterDateConfig;
  @Input()
  public panelReferer: HTMLElement;
  @Input()
  public showClear = true;
  @Output()
  public open = new EventEmitter<boolean>();

  protected formControl: FormControl = new FormControl('');

  @ViewChild('calendar', { read: CalendarComponent })
  private calendar: CalendarComponent;
  private modelTouched: () => void = noop;
  private modelChanged: (value: string) => void = noop;
  private readonly subscription = new Subscription();

  constructor(@Optional() filterActivator: FilterActivatorDirective) {
    this.subscription.add(
      this.formControl.valueChanges.subscribe({
        next: (dateString) => {
          this.modelChanged(dateString);
          this.modelTouched();
        },
      })
    );

    this.subscription.add(
      filterActivator?.activate$.subscribe({
        next: () => {
          this.calendar.openPanel();
        },
      })
    );
  }

  writeValue(value: string): void {
    const dateString: string = this.parseDate(value);

    this.formControl.setValue(dateString);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable({ emitEvent: false }) : this.formControl.enable({ emitEvent: false });
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.calendar.open.subscribe({
        next: (open: boolean) => {
          this.open.emit(open);
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  private parseDate(value: string): string {
    if (!value) {
      return '';
    }

    const date: Date = this.config.dateFormat
      ? DateParser.parse(value, this.config.dateFormat)
      : new Date(value);

    if (isNaN(date.getTime())) {
      return '';
    }

    return this.config.dateFormat ? value : date.toISOString().slice(0, 10);
  }
}
