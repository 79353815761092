<li
  *ngIf="menuItem"
  routerLinkActive="active"
  class="menu-item-container"
>
  <a
    #menuItemElement
    [attr.data-qa]="'menu-item-' + menuItem.name"
    [routerLink]="menuItem.url"
    (mouseover)="updateSubmenuPosition()"
    tabindex="-1"
    class="menu-item"
  >
    <i [ngClass]="menuItem.icon"></i>
    <span class="menu-item-label">
      {{ 'menu.item.' + menuItem.name + '.name' | translate }}
    </span>
  </a>
  <div
    [class.with-items]="submenuItems.length > 0"
    [class.display-top]="displayTop"
    [style.margin-top.px]="submenuPositionCorrection"
    class="submenu-wrapper"
  >
    <ul
      #submenuElement
      class="submenu"
    >
      <li>
        <a
          [attr.data-qa]="'menu-item-' + menuItem.name"
          [routerLink]="menuItem.url"
          tabindex="-1"
          class="submenu-header submenu-header-top"
        >
          {{ 'menu.item.' + menuItem.name + '.name' | translate }}
        </a>
      </li>
      <li *ngFor="let submenuItem of submenuItems; let first = first; let last = last">
        <a
          #rla="routerLinkActive"
          [attr.data-qa]="'submenu-item-' + submenuItem.name"
          [class.first-submenu-item]="first"
          [class.last-submenu-item]="last"
          [class.active]="submenuItem.active || rla.isActive"
          [routerLink]="submenuItem.url"
          class="submenu-item"
          routerLinkActive
          tabindex="-1"
        >
          {{ 'menu.item.' + submenuItem.name + '.name' | translate }}
        </a>
      </li>
      <li>
        <a
          [attr.data-qa]="'menu-item-' + menuItem.name"
          [routerLink]="menuItem.url"
          tabindex="-1"
          class="submenu-header submenu-header-bottom"
        >
          {{ 'menu.item.' + menuItem.name + '.name' | translate }}
        </a>
      </li>
    </ul>
  </div>
</li>
