import { AfterViewInit, Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ConfigurableDialog, DialogService } from '@proget-shared/dialog';
import { DialogTemplateService } from '@proget-shared/dialog/dialog-template';
import { ResponseErrorTemplateMapper } from '@proget-shared/helper/response-error';

import { WorkStatus } from '../../const/work-status.enum';
import { ErrorWorkReport, WorkReport } from '../../type/work-report.type';

@Component({
  selector: 'app-work-progress-dialog',
  templateUrl: './work-progress-dialog.component.html',
  styleUrls: ['./work-progress-dialog.component.scss'],
})
export class WorkProgressDialogComponent implements ConfigurableDialog, AfterViewInit {
  protected readonly WorkStatus = WorkStatus;

  protected errorsDialog: boolean | any = false;
  protected headerKey = '';
  protected currentReportsCount = 0;
  protected totalReportsCount = 0;

  @ViewChild('reports', { read: ViewContainerRef })
  private reportContainer: ViewContainerRef;
  private reportContainerElement: HTMLElement;
  @ViewChild('reportEntry')
  private reportEntry: TemplateRef<any>;

  constructor(
    private dialog: DialogService,
    private dialogTemplate: DialogTemplateService
  ) {}

  ngAfterViewInit(): void {
    this.reportContainerElement = this.reportContainer.element.nativeElement.parentElement;
  }

  onConfiguration(configuration: any): void {
    this.errorsDialog = configuration.errorsDialog;
    this.headerKey = configuration.headerKey;
    this.totalReportsCount = configuration.totalReportsCount || 0;

    configuration.report$.subscribe({
      next: (report: WorkReport) => {
        const containerScrollBottom = this.reportContainerElement.scrollTop + this.reportContainerElement.offsetHeight;
        const isScrollAtEnd = containerScrollBottom === this.reportContainerElement.scrollHeight;

        this.currentReportsCount++;
        const reportViewRef = this.reportContainer.createEmbeddedView(this.reportEntry, { $implicit: report });

        if (report.status !== WorkStatus.SUCCESS) {
          reportViewRef.rootNodes[0].classList.add('error-report-message');
        }

        if (isScrollAtEnd) {
          window.requestAnimationFrame(() => {
            this.reportContainerElement.scrollTo(0, this.reportContainerElement.scrollHeight);
          });
        }
      },
      error: () => {
        setTimeout(() => {
          this.close();
        }, 400);
      },
    });
  }

  protected getPercentageProgress(): number {
    return 0 === this.totalReportsCount
      ? 100
      : Math.round(this.currentReportsCount / this.totalReportsCount * 100);
  }

  protected isCloseDisabled(): boolean {
    return this.currentReportsCount < this.totalReportsCount;
  }

  protected close(): void {
    this.dialog.resolve(this);
  }

  protected openErrorDetails(report: ErrorWorkReport): void {
    if (!report.errorObject || !this.errorsDialog) {
      return;
    }

    if ('function' === typeof this.errorsDialog) {
      this.dialog.custom(this.errorsDialog, {
        configuration: { error: report.errorObject, id: report.item.getId() },
      });

      return;
    }

    if ('boolean' === typeof this.errorsDialog) {
      this.dialogTemplate.alert(ResponseErrorTemplateMapper.mapStrings(report.errorObject));
    }
  }
}
