import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { ConditionAction } from './enum/condition-action.enum';
import { DisablingStrategy } from './enum/disabling-strategy.enum';
import { ConditionalFormGroup } from './model/conditional-form-group.model';
import { DependencyField } from './model/dependency-field.model';
import { DependentValidator } from './model/dependent-validator.model';
import { PasswordFormControl } from './model/password-form-control.model';
import { ConditionConfiguration } from './type/condition-configuration.type';
import {
  ConditionalFormArrayConfiguration,
  ConditionalFormChildConfiguration,
  ConditionalFormConfiguration,
  ConditionalFormFieldConfiguration,
  ConditionalFormGroupConfiguration,
} from './type/conditional-form-configuration.type';
import { Field } from './type/field.type';

@Injectable()
export class ConditionalFormGeneratorService implements OnDestroy {
  private readonly forms: ConditionalFormGroup[] = [];

  ngOnDestroy(): void {
    for (const form of this.forms) {
      form.destroy();
    }
  }

  public generate(configuration: ConditionalFormConfiguration): ConditionalFormGroup {
    const collectedConditions: { [fieldPath: string]: ConditionConfiguration[] } = {};
    const collectedValidators: { [fieldPath: string]: (ValidatorFn | DependentValidator)[] } = {};

    const form: FormGroup = this.generateGroup(
      { children: configuration },
      [],
      collectedConditions,
      collectedValidators
    );
    const actionsOrder: ConditionAction[] = [
      ConditionAction.DISABLE,
      ConditionAction.ENABLE,
      ConditionAction.READONLY,
    ];

    const orderedConditions: {
      field: Field;
      conditions: ConditionConfiguration[];
      disablingStrategy: DisablingStrategy;
    }[] = Object.keys(collectedConditions)
      .sort()
      .map((key) => key.split('.'))
      .sort((a, b) => a.length - b.length)
      .map((keyParts) => keyParts.join('.'))
      .map((fieldPath) => ({
        field: {
          path: fieldPath,
          control: form.get(fieldPath),
        },
        conditions: collectedConditions[fieldPath]
          .sort((a, b) => actionsOrder.indexOf(a.action) - actionsOrder.indexOf(b.action))
          .map((conditionConfiguration) => ({
            field: {
              path: conditionConfiguration.field.path,
              control: form.get(conditionConfiguration.field.path),
            },
            values: conditionConfiguration.values,
            action: conditionConfiguration.action,
          })),
        disablingStrategy: this.getFieldConfiguration(configuration, fieldPath).disablingStrategy ?? DisablingStrategy.ANY_CONDITION,
      }));

    const conditionalForm: ConditionalFormGroup = new ConditionalFormGroup(
      form,
      orderedConditions,
      collectedValidators
    );

    this.forms.push(conditionalForm);

    return conditionalForm;
  }

  public getFieldConfiguration(
    configuration: ConditionalFormConfiguration,
    fieldPath: string
  ): ConditionalFormChildConfiguration {
    if (!configuration || typeof fieldPath !== 'string' || fieldPath === '') {
      return null;
    }

    const pathParts: string[] = fieldPath.split('.');
    const firstPart: string = pathParts[0];

    if (pathParts.length === 1) {
      return configuration[firstPart];
    }

    const nextConfig: ConditionalFormChildConfiguration = configuration[firstPart];

    if (nextConfig.hasOwnProperty('children')) {
      return this.getFieldConfiguration((nextConfig as any).children, pathParts.slice(1).join('.'));
    }

    return null;
  }

  private generateGroup(
    configuration: ConditionalFormGroupConfiguration,
    path: string[],
    conditions: { [fieldPath: string]: ConditionConfiguration[] },
    validators: { [fieldPath: string]: (ValidatorFn | DependentValidator)[] }
  ): FormGroup {
    const form: FormGroup = new FormGroup({});

    for (const fieldName in configuration.children) {
      if (!configuration.children.hasOwnProperty(fieldName)) {
        continue;
      }

      form.addControl(
        fieldName,
        this.buildFieldControl(
          fieldName,
          configuration.children[fieldName],
          path,
          conditions,
          validators
        )
      );
    }

    return form;
  }

  private generateArray(
    configuration: ConditionalFormArrayConfiguration,
    path: string[],
    conditions: { [fieldPath: string]: ConditionConfiguration[] },
    validators: { [fieldPath: string]: (ValidatorFn | DependentValidator)[] }
  ): FormArray {
    const form: FormArray = new FormArray(
      configuration.children.map((childConfig: ConditionalFormChildConfiguration, index: number) => this.buildFieldControl(
        `[${index}]`,
        childConfig,
        path,
        conditions,
        validators
      )
      )
    );

    return form;
  }

  private generateField(configuration: ConditionalFormFieldConfiguration): FormControl {
    if (!configuration.passwordRequired) {
      return new FormControl(configuration.hasOwnProperty('value') ? configuration.value : '');
    }

    const passwordRequiredValidator: ValidatorFn =
      configuration.passwordRequired === true
        ? Validators.required
        : configuration.passwordRequired;

    const passwordControl: PasswordFormControl = new PasswordFormControl(
      configuration.hasOwnProperty('value') ? configuration.value : ''
    );

    passwordControl.setRequiredValidator(passwordRequiredValidator);

    return passwordControl;
  }

  private buildFieldControl(
    fieldName: string,
    fieldConfig: ConditionalFormChildConfiguration,
    path: string[],
    conditions: { [fieldPath: string]: ConditionConfiguration[] },
    validators: { [fieldPath: string]: (ValidatorFn | DependentValidator)[] }
  ): FormGroup | FormArray | FormControl {
    const absoluteField: string = [...path, fieldName].join('.');

    for (const condition of fieldConfig.conditions || []) {
      const [conditionField, conditionValue, action]: [
        string,
        string,
        ConditionAction,
      ] = condition.split(':') as [string, string, ConditionAction];

      const absoluteConditionFieldPath: string = this.getAbsoluteFieldPath(conditionField, path);
      const fieldConditions: ConditionConfiguration[] = conditions[absoluteField] || [];

      const matchingFieldCondition: ConditionConfiguration = fieldConditions.find(
        (fieldCondition) => fieldCondition.field.path === absoluteConditionFieldPath &&
          fieldCondition.action === action
      );

      if (matchingFieldCondition) {
        matchingFieldCondition.values.push(conditionValue);
      } else {
        fieldConditions.push({
          field: {
            path: absoluteConditionFieldPath,
            control: null, // this is updated when form is built
          },
          values: [conditionValue],
          action,
        });
      }

      conditions[absoluteField] = fieldConditions;
    }

    const fieldValidators: (ValidatorFn | DependentValidator)[] = this.prepareValidators(
      path,
      fieldConfig
    );

    if (fieldValidators.length > 0) {
      validators[absoluteField] = fieldValidators;
    }

    return fieldConfig.hasOwnProperty('children')
      ? (fieldConfig as ConditionalFormGroupConfiguration | ConditionalFormArrayConfiguration)
          .children instanceof Array
          ? this.generateArray(
            fieldConfig as ConditionalFormArrayConfiguration,
            [...path, fieldName],
            conditions,
            validators
          )
          : this.generateGroup(
            fieldConfig as ConditionalFormGroupConfiguration,
            [...path, fieldName],
            conditions,
            validators
          )
      : this.generateField(fieldConfig as ConditionalFormFieldConfiguration);
  }

  private prepareValidators(
    targetPath: string[],
    configuration: ConditionalFormChildConfiguration
  ): (ValidatorFn | DependentValidator)[] {
    if (!configuration.hasOwnProperty('validators')) {
      return [];
    }

    const validators: (ValidatorFn | DependentValidator)[] = Array.isArray(configuration.validators)
      ? configuration.validators
      : [configuration.validators];

    return validators.map((validator) => {
      if (validator instanceof DependentValidator) {
        for (const argument of validator.rawArgs) {
          if (argument instanceof DependencyField) {
            argument.absolutePath = this.getAbsoluteFieldPath(argument.relativePath, targetPath);
          }
        }
      }

      return validator;
    });
  }

  private getAbsoluteFieldPath(fieldPath: string, path: string[]): string {
    if (!/^[\._]/.test(fieldPath)) {
      // field is absolute
      return fieldPath;
    }

    const trimmed: string = 0 === fieldPath.indexOf('.') ? fieldPath.substring(1) : fieldPath;
    const fieldParts: string[] = trimmed.split('.');
    const basePath: string[] = path.slice();

    while ('_' === fieldParts[0]) {
      fieldParts.shift();
      basePath.pop();
    }

    return [...basePath, ...fieldParts].join('.');
  }
}
