export class MenuItem {
  private _active = false;

  constructor(
    public readonly name: string,
    public readonly urlTree: string[],
    public readonly icon?: string,
    public readonly submenu?: MenuItem[]
  ) {}

  public get url(): string {
    return this.urlTree.join('/');
  }

  public get active(): boolean {
    return this._active;
  }

  public setRouteActive(currentUrl: string): void {
    this._active = this.urlTree.every((route: string): boolean => currentUrl.split(/[\/?#]/).includes(route));
  }
}
