import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { PermissionService } from '@proget-shared/_local';
import { DialogService } from '@proget-shared/dialog';
import { DialogTemplateService } from '@proget-shared/dialog/dialog-template';
import { ResponseErrorTemplateMapper } from '@proget-shared/helper/response-error';
import { OverlayPanelDirective } from '@proget-shared/ui/overlay-panel';
import { ToastService } from '@proget-shared/ui/toast';
import { combineLatest, finalize, Subject, Subscription } from 'rxjs';

import { ActionAppearance } from '../../enum/action-appearance.enum';
import { GridActionPrompt } from '../../enum/grid-action-prompt.enum';
import { GridActionType } from '../../enum/grid-action-type.enum';
import { SelectionStrategy } from '../../enum/selection-strategy.enum';
import { GridActionsService } from '../../grid-actions.service';
import { ConfirmBatchWork, CustomBatchWork, GridAction, InputBatchWork, PhraseBatchWork } from '../../type/grid-action.type';
import { WorkerItem } from '../../type/worker-item.type';

@Component({
  selector: 'app-item-actions',
  templateUrl: './item-actions.component.html',
  styleUrls: ['./item-actions.component.scss'],
  providers: [GridActionsService],
})
export class ItemActionsComponent implements OnDestroy {
  @Output()
  public readonly opened = new EventEmitter<boolean>();
  @Output()
  public readonly clear = new EventEmitter<any[]>();

  protected itemActions: GridAction[] = [];

  private readonly actionsSubject = new Subject<GridAction[]>();
  private readonly itemSubject = new Subject<any>();
  private readonly subscription = new Subscription();

  @ViewChild('actionsPanel', { read: OverlayPanelDirective })
  private overlayPanel: OverlayPanelDirective;
  private _item: any;

  constructor(
    permissionService: PermissionService,
    private toastService: ToastService,
    private gridActionsService: GridActionsService,
    private dialogService: DialogService,
    private dialogTemplateService: DialogTemplateService
  ) {
    this.subscription.add(
      combineLatest([
        this.actionsSubject,
        this.itemSubject,
        permissionService.permissions$,
      ]).subscribe({
        next: ([actions, item]) => {
          this.itemActions = actions
            .filter((action) => action.appearance !== ActionAppearance.GRID_ONLY &&
              permissionService.hasPermissionInstant(action.permission) &&
              this.isActionVisible(action) &&
              this.isActionEnabled(action, item)
            )
            .map((action) => {
              if (action.type !== GridActionType.BATCH_WORK) {
                return action;
              }

              const configuration = action.batchWorkConfiguration;
              const defaultToast = 'actionKey' in configuration
                ? configuration.actionKey
                : configuration.completeHeaderKey;
              const successToast = action.success?.toast ?? defaultToast;
              const errorToast = action.error?.toast ?? action.label;

              return Object.assign({}, action, {
                action: (workerItem: WorkerItem, data: any) => {
                  switch (action.prompt?.type) {
                    case GridActionPrompt.INPUT:
                      return (action as InputBatchWork<WorkerItem>).action(workerItem.getId(), workerItem, data);
                    case GridActionPrompt.CUSTOM:
                      return (action as CustomBatchWork<WorkerItem>).action(workerItem.getId(), data);
                    case GridActionPrompt.PHRASE:
                      return (action as PhraseBatchWork<WorkerItem>).action(workerItem.getId());
                    case GridActionPrompt.CONFIRM:
                    default:
                      return (action as ConfirmBatchWork<WorkerItem>).action(workerItem.getId(), workerItem);
                  }
                },
                type: GridActionType.ACTION_SINGLE,
                success: { ...action.success, toast: successToast },
                error: action.errorsDialog
                  ? { callback: this.openErrorDetails.bind(this, item.getId(), action.errorsDialog) }
                  : { ...action.error, toast: errorToast },
              });
            });
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.actionsSubject.complete();
    this.itemSubject.complete();
  }

  @Input()
  public set actions(actions: GridAction[]) {
    this.actionsSubject.next(actions);
  }

  @Input()
  public set item(item: any) {
    this._item = item;
    this.itemSubject.next(item);
  }

  protected getQaAttribute(action: GridAction): string {
    return `action-${action.label.replace(/\./g, '-')}`;
  }

  protected performAction(action: GridAction): void {
    const actionItems = [this._item];

    if ('function' === typeof action.start?.callback) {
      action.start.callback();
    }

    this.overlayPanel.close(true);
    this.gridActionsService.getActionStream(action, actionItems)
      .pipe(
        finalize(() => {
          const actionFinalize: any = action.finalize || {};

          if ('function' === typeof actionFinalize.callback) {
            actionFinalize.callback();
          }
        })
      )
      .subscribe({
        next: (result) => {
          const defaultSelectionStrategy = action.finalize?.selectionStrategy ?? SelectionStrategy.KEEP_SELECTION;
          const actionSuccess = Object.assign(
            { selectionStrategy: defaultSelectionStrategy },
            action.success || {}
          );

          if ('string' === typeof actionSuccess.toast) {
            this.toastService.success({
              key: actionSuccess.toast,
              params: this.gridActionsService.resolveParams(actionSuccess.toastParams, actionItems),
            });
          }

          if ([SelectionStrategy.ALWAYS_DESELECT, SelectionStrategy.DESELECT_AFTER_ITEM_ACTION].includes(actionSuccess.selectionStrategy)) {
            this.clear.emit(actionItems);
          }

          if ('function' === typeof actionSuccess.callback) {
            actionSuccess.callback(result, actionItems);
          }

          this.gridActionsService.closePendingPrompt();
        },
        error: () => {
          const defaultSelectionStrategy = action.finalize?.selectionStrategy ?? SelectionStrategy.KEEP_SELECTION;
          const actionError = Object.assign(
            { selectionStrategy: defaultSelectionStrategy },
            action.error || {}
          );

          if ([SelectionStrategy.ALWAYS_DESELECT, SelectionStrategy.DESELECT_AFTER_ITEM_ACTION].includes(actionError.selectionStrategy)) {
            this.clear.emit(actionItems);
          }
        },
      });
  }

  protected isActionEnabled(action: GridAction, item: any): boolean {
    if ('function' !== typeof action.enabled) {
      return true;
    }

    return action.enabled(item);
  }

  private isActionVisible(action: GridAction): boolean {
    return 'function' !== typeof action.visibility || action.visibility();
  }

  private openErrorDetails(
    itemId: any,
    errorsDialog: boolean | any,
    errorObject: any
  ): void {
    if (!errorObject || !errorsDialog) {
      return;
    }

    if ('function' === typeof errorsDialog) {
      this.dialogService.custom(errorsDialog, {
        configuration: { error: errorObject, id: itemId },
      });

      return;
    }

    if ('boolean' === typeof errorsDialog) {
      this.dialogTemplateService.alert(ResponseErrorTemplateMapper.mapStrings(errorObject));
    }
  }
}
