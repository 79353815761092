import { Injectable, OnDestroy } from '@angular/core';
import { DialogService } from '@proget-shared/dialog';
import {
  WorkerResult,
  WorkerItem,
  BatchWorker,
  BatchWorkConfiguration,
  BatchWorkOptions,
  WorkerTranslations,
} from '@proget-shared/grid/grid-actions';
import { ObjectHelper } from '@proget-shared/helper';
import { sum } from '@proget-shared/helper/operators';
import {
  ResponseError,
  ResponseErrorFactory,
  ResponseErrorTemplateMapper,
} from '@proget-shared/helper/response-error';
import { TranslateService } from '@proget-shared/translate';
import { ToastService } from '@proget-shared/ui/toast';
import {
  catchError,
  combineLatest,
  delay,
  finalize,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  reduce,
  share,
  Subject,
  Subscription,
  tap,
  throwError,
} from 'rxjs';

import { WorkProgressDialogComponent } from '../component/work-progress-dialog/work-progress-dialog.component';
import { WorkStatus } from '../const/work-status.enum';
import { WorkReport } from '../type/work-report.type';

@Injectable()
export class BatchWorkService implements BatchWorker, OnDestroy {
  private readonly pendingWorksSubject = new Subject<number>();
  private readonly subscription = new Subscription();
  private readonly defaultOptions: BatchWorkOptions = { batchSize: 10 };

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {
    this.subscription.add(
      this.pendingWorksSubject.pipe(sum()).subscribe({
        next: (pendingWorks) => {
          document.body.classList.remove('batch-work-pending');

          if (pendingWorks > 0) {
            document.body.classList.add('batch-work-pending');
          }
        },
      })
    );
  }

  process<T extends WorkerItem>(
    items: T[],
    actionMethod: (id: string | number, item: WorkerItem) => Observable<any>,
    configuration: BatchWorkConfiguration<T>,
    errorsDialog: boolean | any = false
  ): Observable<WorkerResult> {
    const itemsCount: number = items.length;
    const options = { ...this.defaultOptions, ...configuration };
    // TO-DO Transform stream instead
    let processCancelled = false;

    this.pendingWorksSubject.next(1);

    const firstBatch = items.slice(0, options.batchSize);
    const remainingItems = items.slice(options.batchSize);
    const nextItemSubject = new Subject<T>();

    if (remainingItems.length === 0) {
      nextItemSubject.complete();
    }

    const action$: Observable<WorkReport> = merge(
      from(firstBatch),
      nextItemSubject
    )
      .pipe(
        mergeMap<T, Observable<WorkReport>>((item) => (processCancelled
          ? of({
            name: this.readItemDisplayName(item),
            action: this.getSingleActionLabel<T>(configuration, item),
            status: WorkStatus.CANCELLED,
            item,
          })
          : actionMethod(item.getId(), item).pipe(
            delay(1), // delay for share to be updated
            map(() => ({
              name: this.readItemDisplayName(item),
              action: this.getSingleActionLabel<T>(configuration, item),
              status: WorkStatus.SUCCESS,
              item,
            })),
            catchError((errorObject) => this.handleError(errorObject, item, configuration)),
            catchError((errorReport) => {
              processCancelled = true;

              return of(errorReport);
            })
          ))
        ),
        tap({
          next: () => {
            remainingItems.length
              ? nextItemSubject.next(remainingItems.shift())
              : nextItemSubject.complete();
          },
        }),
        finalize(() => {
          this.pendingWorksSubject.next(-1);
        }),
        share()
      );

    return combineLatest([
      // combineLatest is used to wait whole stream for dialog to be closed
      this.openWorkProgress(
        action$,
        itemsCount,
        'actionKey' in configuration ? configuration.actionKey : configuration.actionTitleKey,
        errorsDialog
      ),
      action$.pipe(
        reduce(
          (result, current) => this.incrementBatchWorkResult(result, current),
          { success: [], error: [], partial: false } as WorkerResult
        ),
        tap({
          next: (result) => {
            this.displayCompleteToast(result, configuration);
          },
        })
      ),
    ]).pipe(map((stream: [void, WorkerResult]) => stream[1]));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getSingleActionLabel<T>(
    translations: WorkerTranslations<T>,
    item: T
  ): string {
    if ('actionKey' in translations) {
      return translations.actionKey;
    }

    if ('function' === typeof translations.singleActionKey) {
      return translations.singleActionKey(item);
    }

    return translations.singleActionKey;
  }

  private incrementBatchWorkResult(result: WorkerResult, report: Partial<WorkReport>): WorkerResult {
    return report.status === WorkStatus.SUCCESS
      ? { ...result, success: result.success.concat(report.item) }
      : { ...result, error: result.error.concat(report.item) };
  }

  private handleError<T extends WorkerItem>(
    errorObject: ResponseError,
    item: T,
    translations: WorkerTranslations<T>
  ): Observable<WorkReport> {
    const formErrors: string[] = Array.from(new Set(ObjectHelper.findStrings(this.getFormErrors(errorObject))));
    const report = {
      name: this.readItemDisplayName(item),
      action: this.getSingleActionLabel<T>(translations, item),
      status: WorkStatus.ERROR,
      errorString: ResponseErrorTemplateMapper.map(errorObject?.messages || formErrors),
      errorObject,
      item,
    };

    return errorObject?.code === 0 ? throwError(() => report) : of(report);
  }

  private getFormErrors(errorObject: ResponseError): ResponseError {
    const responseErrorFactoryForm: ResponseError = ResponseErrorFactory.create(errorObject).form;

    if (responseErrorFactoryForm) {
      return responseErrorFactoryForm;
    }

    if (errorObject?.form) {
      return errorObject.form;
    }

    return {};
  }

  private openWorkProgress(
    report$: Observable<WorkReport>,
    totalReportsCount: number,
    headerKey?: string,
    errorsDialog: boolean | any = false
  ): Observable<void> {
    return this.dialogService.custom<WorkProgressDialogComponent, void>(WorkProgressDialogComponent, {
      configuration: {
        report$,
        totalReportsCount,
        headerKey,
        errorsDialog,
      },
    });
  }

  private displayCompleteToast(result: WorkerResult, translations: WorkerTranslations): void {
    this.toastService.info(
      'actionKey' in translations
        ? translations.actionKey
        : translations.completeHeaderKey,
      {
        key: 'proget_shared.batch_work.status.information',
        params: result,
      },
      {
        duration: 10000,
      }
    );
  }

  private readItemDisplayName(item: WorkerItem): string {
    if (
      typeof item.isDisplayNameTranslationRequired === 'function' &&
      item.isDisplayNameTranslationRequired()
    ) {
      return this.translateService.instant(item.getDisplayName());
    }

    return item.getDisplayName();
  }
}
