import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, Optional, Output, Provider, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CalendarComponent } from '@proget-shared/form/calendar';
import { noop, Subscription } from 'rxjs';

import { FilterActivatorDirective } from '../../directive/filter-activator.directive';
import { Range } from '../../model/range';
import { FilterRangeDateConfig } from '../../type/filter-config.type';

const FILTER_RANGE_DATE_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FilterRangeDateComponent),
  multi: true,
};

@Component({
  selector: 'app-filter-range-date',
  templateUrl: './filter-range-date.component.html',
  styleUrls: ['./filter-range-date.component.scss'],
  providers: [FILTER_RANGE_DATE_VALUE_ACCESSOR],
})
export class FilterRangeDateComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input()
  public config: FilterRangeDateConfig;
  @Input()
  public panelReferer: HTMLElement;
  @Output()
  public open = new EventEmitter<boolean>();

  protected readonly rangeForm = new FormControl({ from: '', to: '' });

  private readonly subscription = new Subscription();

  @ViewChild('calendar', { read: CalendarComponent })
  private calendar: CalendarComponent;
  private modelTouched: () => void = noop;
  private modelChanged: (value: Range) => void = noop;

  constructor(@Optional() filterActivator: FilterActivatorDirective) {
    this.subscription.add(
      this.rangeForm.valueChanges
        .subscribe({
          next: (value) => {
            this.modelTouched();
            this.modelChanged(new Range(value.from, value.to));
          },
        })
    );

    this.subscription.add(
      filterActivator?.activate$.subscribe({
        next: () => {
          this.calendar.openPanel();
        },
      })
    );
  }

  writeValue(value: Range): void {
    this.rangeForm.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: Range) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.rangeForm.disable({ emitEvent: false }) : this.rangeForm.enable({ emitEvent: false });
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.calendar.open.subscribe({
        next: (open: boolean) => {
          this.open.emit(open);
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  protected getShowTime(): boolean {
    return this.config?.dateWithTime ?? false;
  }
}
