import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
  Output,
  Provider,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownComponent, DropdownItem } from '@proget-shared/form/dropdown';
import { BehaviorSubject, noop, Observable, Subscription } from 'rxjs';

import { DEFAULT_SELECT_CONFIG } from '../../const/default-select-config.const';
import { FilterActivatorDirective } from '../../directive/filter-activator.directive';
import { FilterSelectConfig } from '../../type/filter-config.type';

const FILTER_SELECT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FilterSelectComponent),
  multi: true,
};

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
  providers: [FILTER_SELECT_VALUE_ACCESSOR],
})
export class FilterSelectComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input()
  public showClear = true;
  @Input()
  public panelReferer: HTMLElement;
  @Output()
  public open = new EventEmitter<boolean>();

  protected formControl: FormControl = new FormControl();
  protected dropdownOptions$: Observable<DropdownItem[]>;
  protected itemTemplate: TemplateRef<any> = null;
  protected isValid = true;

  private readonly subscription = new Subscription();
  private readonly optionsSubject = new BehaviorSubject<DropdownItem[]>([]);

  @ViewChild('dropdown')
  private dropdown: DropdownComponent;
  private modelTouched: () => void = noop;
  private modelChanged: (value: string) => void = noop;
  private _config: FilterSelectConfig;

  constructor(@Optional() filterActivator: FilterActivatorDirective) {
    this.subscription.add(
      this.formControl.valueChanges
        .subscribe({
          next: (value) => {
            this.modelChanged(value);
            this.modelTouched();
          },
        })
    );

    this.subscription.add(
      this.optionsSubject.subscribe({
        next: () => {
          this.reloadFormValue();
        },
      })
    );

    this.subscription.add(
      filterActivator?.activate$.subscribe({
        next: () => {
          this.dropdown.openPanel();
        },
      })
    );

    this.dropdownOptions$ = this.optionsSubject.asObservable();
  }

  writeValue(value: string): void {
    this.setFormValue(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable({ emitEvent: false }) : this.formControl.enable({ emitEvent: false });
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.dropdown.open.subscribe({
        next: (open: boolean) => {
          this.open.emit(open);
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  @Input()
  public set config(config: FilterSelectConfig) {
    this.itemTemplate = config.template?.dropdownItem || null;

    this.optionsSubject.next(config.options);
    this._config = Object.assign({}, DEFAULT_SELECT_CONFIG, config);
  }

  public get config(): FilterSelectConfig {
    return this._config;
  }

  private reloadFormValue(): void {
    this.setFormValue(this.formControl.value);
  }

  private setFormValue(value: string): void {
    this.isValid = this.isProperValue(value);

    if (!this.isValid) {
      this.formControl.setValue(null, { emitEvent: false });

      return;
    }

    this.formControl.setValue(value || null);
  }

  private isProperValue(value: string): boolean {
    if (!value || !this.config || !Array.isArray(this.config.options)) {
      return true;
    }

    const optionsValues: string[] = this.config.options.map((option) => option.value);

    return optionsValues.indexOf(value) !== -1;
  }
}
