<app-dialog-header [header]="headerKey | translate"></app-dialog-header>
<app-dialog-content>
  <div class="p-grid">
    <div class="p-col-12">
      <app-progress-bar [progress]="getPercentageProgress()"></app-progress-bar>
    </div>
    <div class="reports-container p-col-12">
      <ng-container #reports></ng-container>
    </div>
  </div>
</app-dialog-content>
<div class="dialog-footer">
  <div class="button-container">
    <button
      [disabled]="isCloseDisabled()"
      (click)="close()"
      data-qa="work-progress-dialog-close-button"
      type="button"
      class="proget-button text-only"
    >
      {{ 'proget_shared.batch_work.close.label' | translate }}
    </button>
  </div>
</div>

<ng-template
  #errorTemplate
  let-report
>
  <ng-container *ngIf="!errorsDialog && !report.errorString">
    {{ 'proget_shared.batch_work.status.error' | translate }}
  </ng-container>
  <ng-container *ngIf="!errorsDialog && report.errorString">
    {{ report.errorString }}
  </ng-container>
  <span
    *ngIf="errorsDialog"
    (click)="openErrorDetails(report)"
    class="display-errors-trigger"
  >
    {{ 'proget_shared.batch_work.display_errors.label' | translate }}
  </span>
</ng-template>
<ng-template
  #reportEntry
  let-report
>
  <div
    data-qa="work-report"
    class="p-grid p-align-center work-report"
  >
    <div class="p-col-8 wrap-text">
      {{ report.name }}
    </div>
    <div
      [ngSwitch]="report.status"
      class="p-col-4 wrap-text"
    >
      <ng-container *ngSwitchCase="WorkStatus.SUCCESS">
        {{ 'proget_shared.batch_work.status.done' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="WorkStatus.CANCELLED">
        {{ 'proget_shared.batch_work.status.cancelled' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="WorkStatus.ERROR">
        <ng-container *ngTemplateOutlet="errorTemplate; context { $implicit: report }"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
