import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { debounceTime, merge, Observable, startWith, Subject, Subscription } from 'rxjs';

import { ConditionAction } from '../enum/condition-action.enum';
import { DisablingStrategy } from '../enum/disabling-strategy.enum';
import { FormValueMask } from '../enum/form-value-mask.enum';
import { ConditionConfiguration } from '../type/condition-configuration.type';
import { Field } from '../type/field.type';

import { DependencyField } from './dependency-field.model';
import { DependentValidator } from './dependent-validator.model';

enum FieldStatus {
  UNKNOWN = 'unknown',
  DISABLED = 'disabled',
  READONLY = 'readonly',
  ENABLED = 'enabled',
}

export class ConditionalFormGroup extends FormGroup {
  private readonly disabilityUpdateSubject = new Subject<void>();
  private readonly subscription = new Subscription();

  private validatorsSubscription = new Subscription();
  private configurationValidators: { [path: string]: ValidatorFn[] } = {};
  private validationDependencies: {
    [path: string]: AbstractControl[];
  } = {};
  private getCacheStorage: { [path: string]: AbstractControl } = {};
  private statuses: { [fieldPath: string]: FieldStatus } = {};
  private rootStatus: FieldStatus = FieldStatus.ENABLED;

  constructor(
    form: FormGroup,
    conditions: { field: Field; conditions: ConditionConfiguration[]; disablingStrategy: DisablingStrategy }[],
    validators: { [fieldPath: string]: (ValidatorFn | DependentValidator)[] }
  ) {
    super(form.controls);

    this.subscription.add(
      merge(
        ...conditions
          .map((fieldConditions) => fieldConditions.conditions)
          .reduce((flatten, current) => flatten.concat(current), [])
          .map((condition) => condition.field)
          .filter((field, index, allFields) => index === allFields.indexOf(field))
          .map((field) => field.control.valueChanges)
      )
        .pipe(startWith(true), debounceTime(0))
        .subscribe({
          next: () => {
            this.updateFieldsDisability(form, conditions);
          },
        })
    );

    this.assignValidators(validators);
  }

  public get disabilityUpdate$(): Observable<void> {
    return this.disabilityUpdateSubject as Observable<void>;
  }

  public get valueWithReadonly(): any {
    return this.readGroup(this);
  }

  public getConfigurationValidators(path: string): ValidatorFn[] {
    return this.configurationValidators.hasOwnProperty(path)
      ? this.configurationValidators[path]
      : [];
  }

  public enable(): void {
    this.rootStatus = FieldStatus.ENABLED;
    super.enable();
  }

  public disable(): void {
    this.rootStatus = FieldStatus.DISABLED;
    super.disable();
  }

  public destroy(): void {
    this.subscription.unsubscribe();
    this.validatorsSubscription.unsubscribe();
  }

  private readGroup(group: FormGroup, path: string[] = []): any {
    const groupValue: any = {};

    for (const field in group.controls) {
      if (!group.controls.hasOwnProperty(field)) {
        continue;
      }

      const control: AbstractControl = group.controls[field];
      const fieldPath: string[] = [...path, field];

      if (FieldStatus.DISABLED === this.getFieldStatus(fieldPath.join('.'))) {
        continue;
      }

      if (control instanceof FormGroup) {
        groupValue[field] = this.readGroup(control, fieldPath);
      } else if (control instanceof FormArray) {
        groupValue[field] = this.readArray(control, fieldPath);
      } else if (control instanceof FormControl) {
        groupValue[field] = control.value;
      }
    }

    return groupValue;
  }

  private readArray(group: FormArray, path: string[] = []): any {
    return group.controls
      .filter((_control, index) => {
        const field = `[${index}]`;
        const fieldPath: string[] = [...path, field];
        const fieldStatus: FieldStatus = this.getFieldStatus(fieldPath.join('.'));

        return FieldStatus.DISABLED !== fieldStatus;
      })
      .map((control, index) => {
        const field = `[${index}]`;
        const fieldPath: string[] = [...path, field];

        if (control instanceof FormGroup) {
          return this.readGroup(control, fieldPath);
        }

        if (control instanceof FormArray) {
          return this.readArray(control, fieldPath);
        }

        if (control instanceof FormControl) {
          return control.value;
        }

        return null;
      });
  }

  private updateFieldsDisability(
    form: FormGroup,
    fieldConditions: { field: Field; conditions: ConditionConfiguration[]; disablingStrategy: DisablingStrategy }[],
    counter = 0,
    history: string[] = []
  ): void {
    if (counter > 100) {
      throw new Error(`Infinity loop\n\n...\n${history.slice(-20).join('\n')}`);
    }

    for (const { field, conditions, disablingStrategy } of fieldConditions) {
      const disablingCondition: ConditionConfiguration | boolean = disablingStrategy === DisablingStrategy.ANY_CONDITION
        ? conditions.find((condition) => this.isDisablingCondition(condition))
        : conditions.every((condition) => this.isDisablingCondition(condition));

      const currentStatus: FieldStatus = this.getFieldStatus(field.path);
      // condition status based on found condition
      // TODO - sprawdzić poprawność działania READONLY oraz przerobić aby status współgrał także z DisablingStrategy.All_CONDITIONS
      const conditionStatus: FieldStatus = !disablingCondition
        ? FieldStatus.ENABLED
        : typeof disablingCondition !== 'boolean' && ConditionAction.READONLY === disablingCondition.action
          ? FieldStatus.READONLY
          : FieldStatus.DISABLED;
      // parent status
      const parentStatus: FieldStatus = this.getFieldStatus(this.getConditionalParentField(field));
      // basing on parent status prevents enabling fields in disabled groups or readonly in disabled
      const nextStatus: FieldStatus = this.sumStatuses(conditionStatus, parentStatus);

      // update saved status
      this.statuses[field.path] = nextStatus;

      if (nextStatus === currentStatus) {
        continue;
      }

      // save status for children
      const childPrefix = `${field}.`;

      for (const statusField in this.statuses) {
        if (this.statuses.hasOwnProperty(statusField) && 0 === statusField.indexOf(childPrefix)) {
          this.statuses[statusField] = nextStatus;
        }
      }

      // update controls disability
      if (nextStatus !== FieldStatus.ENABLED) {
        // disable for READONLY and DISABLED
        field.control.disable({ emitEvent: true });
      } else if (FieldStatus.ENABLED === parentStatus) {
        // enable only if parent is enabled
        field.control.enable({ emitEvent: true });
      }

      if (this.validationDependencies.hasOwnProperty(field.path)) {
        for (const majorField of this.validationDependencies[field.path]) {
          majorField.updateValueAndValidity();
        }
      }

      return this.updateFieldsDisability(form, fieldConditions, ++counter, [
        ...history,
        `${currentStatus} > ${nextStatus} ${field}`,
      ]);
    }

    this.disabilityUpdateSubject.next();
  }

  private sumStatuses(...statuses: FieldStatus[]): FieldStatus {
    const order: FieldStatus[] = [FieldStatus.ENABLED, FieldStatus.READONLY, FieldStatus.DISABLED];

    return order[Math.max(...statuses.map((status) => order.indexOf(status)))];
  }

  private getConditionalParentField(field: Field): Field {
    if (field.hasOwnProperty('conditionalParent')) {
      return field.conditionalParent;
    }

    const conditionalParentPath: string = this.getConditionalParentPath(field.path);
    const conditionalParentField: Field = {
      path: conditionalParentPath,
      control: this.getCached(conditionalParentPath),
    };

    field.conditionalParent = conditionalParentField;

    return conditionalParentField;
  }

  private getConditionalParentPath(path: string): string {
    if (!path) {
      return '';
    }

    const parentPath: string = path.split('.').slice(0, -1)
      .join('.');

    if (this.statuses.hasOwnProperty(parentPath)) {
      return parentPath;
    }

    return this.getConditionalParentPath(parentPath);
  }

  private getFieldStatus(fieldOrPath: Field | string): FieldStatus {
    const path: string = typeof fieldOrPath === 'string' ? fieldOrPath : fieldOrPath.path;

    if (!path) {
      return this.rootStatus;
    }

    if (!this.statuses.hasOwnProperty(path)) {
      return FieldStatus.UNKNOWN;
    }

    const savedStatus: FieldStatus = this.statuses[path];
    const control: AbstractControl =
      typeof fieldOrPath === 'string' ? this.getCached(path) : fieldOrPath.control;

    if (
      // groups can be disabled by their children - FormControls check only
      control instanceof FormControl && FieldStatus.ENABLED === savedStatus && control.disabled ||
      // disabled groups can not contain enabled children - check all AbstractControls
      FieldStatus.ENABLED !== savedStatus && control.enabled
    ) {
      return FieldStatus.UNKNOWN;
    }

    return savedStatus;
  }

  private isDisablingCondition(condition: ConditionConfiguration): boolean {
    if (FieldStatus.DISABLED === this.getFieldStatus(condition.field.path)) {
      return true;
    }

    const valueMatching: boolean = this.valueMatching(
      condition.field.control.value,
      condition.values
    );

    return ConditionAction.ENABLE === condition.action ? !valueMatching : valueMatching;
  }

  private valueMatching(testValue: any, options: string[]): boolean {
    for (const optionValue of options) {
      if (FormValueMask.TRUTHY === optionValue && testValue) {
        return true;
      }

      if (FormValueMask.FALSY === optionValue && !testValue) {
        return true;
      }

      if (
        FormValueMask.EMPTY_ARRAY === optionValue &&
        Array.isArray(testValue) &&
        testValue.length === 0
      ) {
        return true;
      }

      if (
        FormValueMask.FILLED_ARRAY === optionValue &&
        Array.isArray(testValue) &&
        testValue.length > 0
      ) {
        return true;
      }

      if (optionValue === this.stringifyValue(testValue)) {
        return true;
      }
    }

    return false;
  }

  private stringifyValue(value: any): string {
    if (null === value) {
      return 'null';
    }

    if (void 0 === value) {
      return 'undefined';
    }

    return value.toString();
  }

  private getCached(path: string): AbstractControl {
    if (this.getCacheStorage.hasOwnProperty(path)) {
      return this.getCacheStorage[path];
    }

    const control: AbstractControl = super.get(path);

    this.getCacheStorage[path] = control;

    return control;
  }

  private assignValidators(validators: {
    [fieldPath: string]: (ValidatorFn | DependentValidator)[];
  }): void {
    this.configurationValidators = {};
    this.validationDependencies = {};
    this.validatorsSubscription.unsubscribe();
    this.validatorsSubscription = new Subscription();

    for (const majorPath in validators) {
      if (!validators.hasOwnProperty(majorPath)) {
        continue;
      }

      const field: AbstractControl = this.get(majorPath);
      const fieldValidators: ValidatorFn[] = validators[majorPath].map((validator) => {
        if (!(validator instanceof DependentValidator)) {
          return validator;
        }

        const parsedArguments: any[] = validator.rawArgs.map((argument) => {
          if (!(argument instanceof DependencyField)) {
            return argument;
          }

          const dependencyField: AbstractControl = this.get(argument.absolutePath);

          if (!this.validationDependencies.hasOwnProperty(argument.absolutePath)) {
            this.validationDependencies[argument.absolutePath] = [];
          }

          if (this.validationDependencies[argument.absolutePath].indexOf(field) === -1) {
            this.validationDependencies[argument.absolutePath].push(field);
          }

          return dependencyField;
        });

        return validator.validator.apply(validator, parsedArguments);
      });

      this.configurationValidators[majorPath] = fieldValidators;

      field.setValidators(fieldValidators);
    }

    for (const dependencyPath in this.validationDependencies) {
      if (!this.validationDependencies.hasOwnProperty(dependencyPath)) {
        continue;
      }

      this.validatorsSubscription.add(
        this.get(dependencyPath).valueChanges.subscribe({
          next: () => {
            for (const majorField of this.validationDependencies[dependencyPath]) {
              const majorFieldValidity = majorField.valid;

              majorField.updateValueAndValidity({ emitEvent: false });
              const validityChanged = majorFieldValidity !== majorField.valid;

              if (validityChanged) {
                majorField.updateValueAndValidity();
                majorField.markAsTouched({ onlySelf: true });
              }
            }
          },
        })
      );
    }
  }
}
